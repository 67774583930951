<template>
    <article>
        <section class="page">
            <div class="center-content">
                <div class="content">
                    <AppTop />
                    <div class="center">
                        <div class="logo-home">
                            <img src="../assets/images/logo-1.svg" />
                        </div>
                    </div>
                    <AppMenu />
                </div>
            </div>
        </section>
        <section class="motif">
            <div v-if="motif == 1" class="motif-1">
                <img src="../assets/images/img1.png" />
            </div>

            <div v-else-if="motif == 2" class="motif-2">
                <img src="../assets/images/img2.png" />
            </div>

            <div v-else-if="motif == 3" class="motif-3">
                <img src="../assets/images/img3.png" />
            </div>

            <div v-else-if="motif == 4" class="motif-4">
                <img src="../assets/images/img4.png" />
            </div>

            <div v-else class="motif-5">
                <img src="../assets/images/img5.png" />
            </div>
        </section>
    </article>
</template>

<script>
// @ is an alias to /src
import AppTop from "@/components/global/AppTop.vue";
import AppMenu from "@/components/global/AppMenu.vue";

export default {
    name: "HomeView",
    components: {
        AppTop,
        AppMenu,
    },
    data: function () {
        return {
            motif: Math.floor(Math.random() * 5) + 1,
        };
    },
};
</script>

<style scoped lang="scss">
.logo-home {
    width: 100%;
    max-width: 255px;
    margin: 0 auto;
    svg {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .logo-home {
        max-width: 180px;
    }
}
</style>
