<template>
    <div class="top">
        <div class="logo-page" v-if="showlogo">
            <router-link to="/" exact>
                <img src="../../assets/images/logo-2.svg" />
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "AppTop",
    props: {
        showlogo: Boolean,
    },
};
</script>
<style scoped lang="scss">
.logo-page {
    width: 100%;
    max-width: 310px;
    margin: 0 auto;
}
.logo-page svg,
.logo-home svg {
    width: 100%;
}
</style>
function appTop($showlogo){ }
