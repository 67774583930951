<template>
    <div class="bottom">
        <div class="menu">
            <router-link to="/contact">Contact</router-link>
            <router-link to="/about">About</router-link>
            <a
                v-if="instagram"
                class="instagram"
                href="https://www.instagram.com/atelierlamarck/"
                target="_blank"
                rel="noopener noreferrer"
                >@atelierlamarck</a
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "AppMenu",
    props: {
        instagram: Boolean,
    },
};
</script>
<style scoped lang="scss">
.menu {
    text-align: center;
    a {
        font-family: "GranjonLTStd";
        font-size: 20px;
        color: #000;
        text-transform: lowercase;
        margin-right: 70px;
        transition: all 0.4s;
        font-variant-caps: petite-caps;
        letter-spacing: 0.8px;
        &:last-child {
            margin-right: 0;
        }
        &.instagram {
            font-family: "GranjonLTStd-Italic";
            text-transform: lowercase;
            font-variant-caps: normal;
            letter-spacing: normal;
        }
    }
}
@media (max-width: 768px) {
    .menu {
        a {
            font-size: 18px;
        }
    }
}
</style>
