<template>
    <article>
        <section>
            <div class="center-content">
                <div class="content">
                    <AppTop showlogo />
                    <div class="center">
                        <div class="text footer-text text-contact-info">
                            <p>
                                <!-- <a href="tel:+33686923387"
                                    >T. +33 6 86 92 33 87</a
                                > -->
                                <br /><em>à Montmartre,</em> 75018 Paris
                                <br /><a href="mailto:mail@atelierlamarck.com"
                                    >mail@atelierlamarck.com</a
                                >

                                <br />
                                <!-- <a
                                    href="https://www.instagram.com/atelierlamarck/"
                                >
                                    <img
                                        class="iconins"
                                        src="../assets/images/instagram.png"
                                    /> -->
                                <!-- <em class="suive">suivez-nous</em> -->
                                <!-- </a> -->
                            </p>
                            <p>
                                <img
                                    class="icon"
                                    src="../assets/images/icon.svg"
                                />
                            </p>
                        </div>
                    </div>
                    <AppMenu instagram />
                </div>
            </div>
        </section>
    </article>
</template>
<script>
// @ is an alias to /src
import AppTop from "@/components/global/AppTop.vue";
import AppMenu from "@/components/global/AppMenu.vue";

export default {
    name: "ContactView",
    components: {
        AppTop,
        AppMenu,
    },
};
</script>
<style scoped lang="scss">
@media (max-width: 768px) {
    // .center {
    //     margin-top: auto;
    // }
}
</style>
